// fix width
.@{icon-css-prefix}-fw {
  width: (18em / 14);
  text-align: center;
}

// size
.@{icon-css-prefix}-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}
.@{icon-css-prefix}-sm {
  font-size: (3em / 4);
}
.@{icon-css-prefix}-1x { font-size: 1em; }
.@{icon-css-prefix}-2x { font-size: 2em; }
.@{icon-css-prefix}-3x { font-size: 3em; }
.@{icon-css-prefix}-4x { font-size: 4em; }
.@{icon-css-prefix}-5x { font-size: 5em; }

// stack
.@{icon-css-prefix}-s {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  > i{
  	position: absolute;
	  left: 0;
	  width: 100%;
	  text-align: center;
	  line-height: inherit;
  }
}
.@{icon-css-prefix}-s-2x {
  width: 2em;
  height: 2em;
  font-size: 2em;
  line-height: 2em;
  .i-s-base{
    font-size: 2em;
  }
}
.@{icon-css-prefix}-s-3x {
  width: 2.5em;
  height: 2.5em;
  font-size: 2.5em;
  line-height: 2.5em;
  .i-s-base{
    font-size: 2.5em;
  }
}
.@{icon-css-prefix}-s-4x {
  width: 3em;
  height: 3em;
  font-size: 3em;
  line-height: 3em;
  .i-s-base{
    font-size: 3em;
  }
}
.@{icon-css-prefix}-s-5x {
  width: 3.5em;
  height: 3.5em;
  font-size: 3.5em;
  line-height: 3.5em;
  .i-s-base{
    font-size: 3.5em;
  }
}