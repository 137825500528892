.pos-rlt{position: relative;}
.pos-stc{position: static;}
.pos-abt{position: absolute;}
.line {*width: 100%;height: 2px;margin: 10px 0;font-size:0;overflow: hidden;}
.line-xs{margin: 0}
.line-lg{margin-top:15px;margin-bottom: 15px}
.line-dashed{border-style: dashed !important;background-color: transparent;border-width:0;}
.no-line{border-width: 0}
.no-border, .no-borders{border-color:transparent;border-width:0}
.no-radius{border-radius: 0}
.block{display:block;}
.block.hide{display: none;}
.inline{display:inline-block !important;}
.none{display: none;}
.pull-right-lg{float: right;}
.pull-none{float: none;}
.rounded{border-radius: 500px;}

.btn-s-xs{min-width: 90px}
.btn-s-sm{min-width: 100px}
.btn-s-md{min-width: 120px}
.btn-s-lg{min-width: 150px}
.btn-s-xl{min-width: 200px}

.l-h-2x{line-height: 2em;}
.l-h-1x{line-height: 1.2;}
.l-h{line-height: 1.5;}
.v-middle{vertical-align: middle !important;}
.v-top{vertical-align: top !important;}
.v-bottom{vertical-align: bottom !important;}

.font-normal{font-weight: normal;}
.font-thin{font-weight: 300;}
.font-bold{font-weight: 700;}

.text-lg{font-size: @font-size-lg;}
.text-md{font-size: @font-size-md;}
.text-sm{font-size: @font-size-sm;}
.text-xs{font-size: @font-size-xs;}
.text-ellipsis{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}
.text-u-c{text-transform: uppercase;}
.text-l-t{text-decoration: line-through;}
.text-u-l{text-decoration: underline;}
.text-active, .active > .text, .active > .auto .text{display: none !important;}
.active > .text-active, .active > .auto .text-active{display: inline-block !important;}
.box-shadow{
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}

.wrapper-xxs{padding: 2px 6px;}
.wrapper-xs{padding: 4px 8px;}
.wrapper-sm{padding: 5px 10px;}
.wrapper{padding: 15px;}
.wrapper-md{padding: 20px;}
.wrapper-lg{padding: 30px;}
.wrapper-xl{padding: 50px;}
.padder{padding-left:15px;padding-right: 15px}
.padder-v{padding-top:15px;padding-bottom: 15px}
.no-padder{padding: 0 !important;}
.pull-in{margin-left: -15px;margin-right: -15px;}
.pull-out{margin:-10px -15px;}

.b{border: 1px solid rgba(0, 0, 0, 0.05)}
.b-a{border: 1px solid @border-color}
.b-t{border-top: 1px solid @border-color}
.b-r{border-right: 1px solid @border-color}
.b-b{border-bottom: 1px solid @border-color}
.b-l{border-left: 1px solid @border-color}
.b-light{border-color: darken(@brand-light, 5%)}
.b-dark{border-color: lighten(@brand-dark, 5%)}
.b-primary{border-color: lighten(@brand-primary, 5%)}
.b-success{border-color: lighten(@brand-success, 5%)}
.b-info{border-color: lighten(@brand-info, 5%)}
.b-warning{border-color: lighten(@brand-warning, 5%)}
.b-danger{border-color: lighten(@brand-danger, 5%)}
.b-black{border-color: lighten(@brand-black, 5%)}
.b-white{border-color: #fff}
.b-dashed{border-style: dashed !important;}

.b-2x{border-width: 2px}
.b-3x{border-width: 3px}
.b-4x{border-width: 4px}
.b-5x{border-width: 5px}

.r{
  border-radius: @border-radius-base @border-radius-base @border-radius-base @border-radius-base;
}
.r-l{
  border-radius: @border-radius-base 0 0 @border-radius-base;
}

.r-r{
  border-radius: 0 @border-radius-base @border-radius-base 0;
}

.r-t{
  border-radius: @border-radius-base @border-radius-base 0 0;
}

.r-b{
  border-radius: 0 0 @border-radius-base @border-radius-base;
}

.m-xxs{margin: 2px 4px}
.m-xs{margin: 5px;}
.m-sm{margin: 10px;}
.m{margin: 15px;}
.m-md{margin: 20px;}
.m-lg{margin: 30px;}
.m-xl{margin: 50px;}
.m-n{margin: 0 !important}

.m-l-none{margin-left: 0}
.m-l-xs{margin-left: 5px;}
.m-l-sm{margin-left: 10px;}
.m-l{margin-left: 15px}
.m-l-md{margin-left: 20px;}
.m-l-lg{margin-left: 30px;}
.m-l-xl{margin-left: 40px;}

.m-l-n-xxs{margin-left: -1px}
.m-l-n-xs{margin-left: -5px}
.m-l-n-sm{margin-left: -10px}
.m-l-n{margin-left: -15px}
.m-l-n-md{margin-left: -20px}
.m-l-n-lg{margin-left: -30px}
.m-l-n-xl{margin-left: -40px}

.m-t-none{margin-top:0}
.m-t-xxs{margin-top: 1px;}
.m-t-xs{margin-top: 5px;}
.m-t-sm{margin-top: 10px;}
.m-t{margin-top: 15px}
.m-t-md{margin-top: 20px;}
.m-t-lg{margin-top: 30px;}
.m-t-xl{margin-top: 40px;}

.m-t-n-xxs{margin-top: -1px}
.m-t-n-xs{margin-top: -5px}
.m-t-n-sm{margin-top: -10px}
.m-t-n{margin-top: -15px}
.m-t-n-md{margin-top: -20px}
.m-t-n-lg{margin-top: -30px}
.m-t-n-xl{margin-top: -40px}

.m-r-none{margin-right: 0}
.m-r-xxs{margin-right: 1px}
.m-r-xs{margin-right: 5px}
.m-r-sm{margin-right: 10px}
.m-r{margin-right: 15px}
.m-r-md{margin-right: 20px}
.m-r-lg{margin-right: 30px}
.m-r-xl{margin-right: 40px}

.m-r-n-xxs{margin-right: -1px}
.m-r-n-xs{margin-right: -5px}
.m-r-n-sm{margin-right: -10px}
.m-r-n{margin-right: -15px}
.m-r-n-md{margin-right: -20px}
.m-r-n-lg{margin-right: -30px}
.m-r-n-xl{margin-right: -40px}

.m-b-none{margin-bottom: 0}
.m-b-xxs{margin-bottom: 1px;}
.m-b-xs{margin-bottom: 5px;}
.m-b-sm{margin-bottom: 10px;}
.m-b{margin-bottom: 15px;}
.m-b-md{margin-bottom: 20px;}
.m-b-lg{margin-bottom: 30px;}
.m-b-xl{margin-bottom: 40px;}

.m-b-n-xxs{margin-bottom: -1px}
.m-b-n-xs{margin-bottom: -5px}
.m-b-n-sm{margin-bottom: -10px}
.m-b-n{margin-bottom: -15px}
.m-b-n-md{margin-bottom: -20px}
.m-b-n-lg{margin-bottom: -30px}
.m-b-n-xl{margin-bottom: -40px}

.media-xs{min-width: 50px}
.media-sm{min-width: 80px}
.media-md{min-width: 90px}
.media-lg{min-width: 120px}

.avatar{
  position: relative;
  display: block;
  border-radius: 500px;
  white-space: nowrap;
  img{
    border-radius: 500px;
    width: 100%;
  }
  i{
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    border-width: 2px;
    border-style: solid;
    border-radius: 100%;
    &.md{
      width: 12px;
      height: 12px;
      margin: 1px;
    }
    &.right{
      left: auto;
      right: 0;
    }
    &.bottom{
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
    }
    &.on{
      background-color: @brand-success;
    }
    &.off{
      background-color: @text-muted;
    }
    &.busy{
      background-color: @brand-danger;
    }
    &.away{
      background-color: @brand-warning;
    }
  }
}

.thumb-lg{width: 128px;display: inline-block}
.thumb-md{width: 64px;display: inline-block}
.thumb{width: 50px;display: inline-block}
.thumb-sm{width: 34px;display: inline-block}
.thumb-xs{width: 24px;display: inline-block}
.thumb-wrapper{padding: 2px; border: 1px solid #ddd}
.thumb img, 
.thumb-xs img, 
.thumb-sm img, 
.thumb-md img, 
.thumb-lg img,
.thumb-btn img{
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.img-full{
  max-width: 100%;
  > img{
    max-width: 100%;
  }
}
.clear{display:block;overflow: hidden;}

.row-sm{
  margin-left: -10px;
  margin-right: -10px;
  > div{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.i-checks{
  input{
    width: auto !important;
    height: auto !important;
    opacity: 0;
    &:checked + i{
      border-color: @brand-primary;
      &:before{
        position: absolute;
        left: 0px;
        width: 100%;
        top: 2px;
        text-align: center;
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        color: @brand-primary;
      }
    }
    &[type="radio"] + i{
      border-radius: 100%;
    }
    &[type="checkbox"]:checked + i:before{
      content:"\f00c";
    }
    &[type="radio"]:checked + i:before{
      content:"\f111";
    }
    &[disabled],
    fieldset[disabled] & {
      & + i{
        border-color: lighten(@input-border, 5%);
        &:before{
          color: @input-border;
        }
      }
    }
  }
  i{
    width: 18px;
    height: 18px;
    line-height: 1;
    border: 1px solid @input-border;
    background-color: #fff;
    margin-left: -20px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    position: relative;
    font-size: 12px;
    .ie8 &{
      display: none;
    }
  }
}

.scroll-x, .scroll-y{overflow:hidden;-webkit-overflow-scrolling:touch;}
.scroll-y{overflow-y:auto;}
.scroll-x{overflow-x:auto;}
.no-touch {
  .scroll-x, 
  .scroll-y{
    overflow: hidden;
  }
  .scroll-x{
    &:hover,
    &:focus,
    &:active{
      overflow-x: auto;
    }
  }
  .scroll-y{
    &:hover,
    &:focus,
    &:active{
      overflow-y: auto;
    }
  }
  .hover-action{
    display: none;    
  }
  .hover:hover {
    .hover-action{
      display: inherit;
    }
  }
}

.hover-rotate{
  .transition(all .2s ease-in-out .1s)
}
.hover:hover .hover-rotate,
.hover:active .hover-rotate{
  .rotate(90deg)
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: #fff;
  &.fade{
    opacity: 0;
    filter: alpha(opacity=0);
  }
  &.in{
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
}

.h{
  font-size: 170px;
  font-weight: 300;
  text-shadow: 0 1px 0 #d9d9d9,0 2px 0 #d0d0d0,0 5px 10px rgba(0,0,0,.125),0 10px 20px rgba(0,0,0,.2);
}

/*desktop*/
@media screen and (min-width: 992px) {
  .col-lg-2-4{width: 20.000%;float: left;}
}

/*phone*/
@media (max-width: 767px) {
  .shift{display: none !important;}
  .shift.in{display: block !important;}
  .row-2 [class*="col"]{width: 50%;float: left}
  .row-2 .col-0{clear: none}
  .row-2 li:nth-child(odd) { clear: left;margin-left: 0}
  .text-center-xs{text-align: center;}
  .text-left-xs{text-align: left;}
  .pull-none-xs{float: none !important;}
  .dropdown-menu.pull-none-xs{left: 0;}
  .hidden-xs.show{display: inherit !important;}
  .wrapper-lg{padding: 15px;}
}