/*layout*/
.header, 
.footer{	
	min-height: 50px;
	padding: 0 15px;
	> p{
		margin-top: 15px;
		display: inline-block;
	}
	> .btn,
	> .btn-group,
	> .btn-toolbar
	{
		margin-top: 10px;
	}
	> .btn-lg{
		margin-top: 0;
	}
	.nav-tabs {
		border: none;
		margin-left: -15px;
		margin-right: -15px;	
		> li {
			a{
				border: none !important;
				border-radius: 0;
				padding-top: 15px;
				padding-bottom: 15px;
				line-height: 20px;
				&:hover, 
				&:focus
				{
					background-color: transparent;
				}
			}
			&.active a{
				color: @text-color;
				&,
				&:hover{
					background-color: @body-bg;
				}
			}
		}
		&.nav-white{
			> li.active a{
				&,
				&:hover{
					background-color: #fff;
				}
			}
		}
	}
	&.navbar{
		border-radius: 0;
		border: none;
		margin-bottom: 0;
		padding:0;
		position: relative;
		z-index: 9;
	}
}
body.container{
	padding: 0;
}

@media (orientation:landscape) {
    html.ios7.ipad > body {
        padding-bottom: 20px;
    }
}

@media (min-width: 768px) {
	body.container{
		.box-shadow(0 3px 60px rgba(0,0,0,0.3));
		border-left: 1px solid darken(@border-color, 10%);
		border-right: 1px solid darken(@border-color, 10%);
	}
	.app{
		&,
		body{
			height:100%;
			overflow: hidden;
		}
		.hbox{
			&.stretch{
				height:100%;
			}
		}
		.vbox{
			> section,
			> footer{
				position: absolute;
			}
			&.flex{
				> section{
					> section{
						overflow: auto;
					}
				}
			}
		}
	}

	.hbox{	
		display: table;
		table-layout: fixed;
		border-spacing: 0;
		width: 100%;
		> aside,
		> section{
			display: table-cell;
			vertical-align: top;
			height: 100%;
			float: none;
			&.show, 
			&.hidden-sm	{
				display: table-cell !important;
			}
		}
	}

	.vbox{
		display: table;
		border-spacing:0;
		position: relative;
		height: 100%;
		width: 100%;
		> section, 
		> footer {
			top: 0;
			bottom: 0;
			width: 100%;
		}
		> header{
			~ section {
				top: 50px;
			}
			&.header-md{
				~ section {
					top: @header-md-height;
				}
			}
		}
		> section{
			&.w-f{
				bottom: 50px;
			}
			&.w-f-md{
				bottom: 60px;
			}
		}
		> footer {
			top: auto;
			z-index: 1000;
		}
		&.flex{
			> header,
			> section,
			> footer {
				position: inherit;
			}
			> section{
				display: table-row;
				height: 100%;
				> section {
					position: relative;
					height: 100%;
					-webkit-overflow-scrolling:touch;
					.ie & {
						display: table-cell;
					}
					> section {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}
			}
		}
	}
	
	.aside-xs{
		width: 60px;
	}
	.aside-sm{
		width: 150px;
	}
	.aside{
		width: 200px;
	}	
	.aside-md{
		width: 240px;
	}
	.aside-lg{
		width: 300px;
	}
	.aside-xl{
		width: 360px;
	}
	.aside-xxl{
		width: 480px;
	}

	.header-md{
		min-height: @header-md-height;
		.navbar-form{
			margin-top: floor( (@header-md-height - 30)/2 );
			margin-bottom: floor( (@header-md-height - 30)/2 );
		}
	}

	.scrollable{
		-webkit-overflow-scrolling:touch;
	}

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background-color:rgba(50,50,50,0.25);
		border: 2px solid transparent;
		border-radius: 10px;
		background-clip: padding-box;
	}
	::-webkit-scrollbar-thumb:hover{
		background-color:rgba(50,50,50,0.5);
	}
	::-webkit-scrollbar-track {
		background-color:rgba(50,50,50,0.05);
	}
}

.scrollable{
	overflow-x: hidden;
	overflow-y: auto;
}

.no-touch {
	.scrollable.hover {
		overflow-y: hidden;
		&:hover
		{
			overflow: visible;
			overflow-y: auto;
		}
	}
	::-webkit-scrollbar-button {
		width: 10px;
		height: 6px;
		background-color:rgba(50,50,50,0.05);
	}
}

.slimScrollBar{
	border-radius: 5px;
	border: 2px solid transparent;
	border-radius: 10px;
	background-clip: padding-box !important;
}


@media print {
  html, body, .hbox, .vbox{
    height: auto;
  }
  .vbox{
		> section,
		> footer{
			position: relative;
		}
	}
}