table tfoot.in-header {
  display: table-header-group;
}

/* add danger color to chosen select */
.form-group.has-error .chosen-default {
  border-color: @brand-danger;
  color: @brand-danger;
}

.handle {
  cursor: grab;
}

.clickable {
  cursor: pointer;
}

#topic tfoot {
  input {
    width: 100%;
  }

  th:first-child {
    width: 100px;
  }
}

.scalable-datatable {
  tbody {
    td {
      padding: 8px 8px !important;
      &:first-child {
        width: 100px;
      }
    }
  }

  tfoot {

    input.form-control {
      width: 100%;
    }

    th {
      padding: 8px 8px !important;
    }
  }

  thead {

    input.form-control {
      width: 100%;
    }

    th {
      padding: 8px 8px !important;
    }
  }

}

.resource-datatable {
  th:last-child {
    width: 90px;
  }
  td:last-child {
    text-align: right;
  }
}

.image-500 {
  @media (min-width: 769px) {
    max-width: 500px;
  }
}

.image-list {
  max-width: 100px;
  max-height: 100px;
}

.datatable-input {
  padding: 3px 6px;
}

/* text in the header */
.nav-item {
  .info-icon {
    padding-right: 5px;
  }
  .info-text {
    font-size: 16px;
  }
}

.modal-image {
  max-width: 90%;
}

.imagepreview {
  width: 100%;
}

#notification-bar {
  .unread {
    background-color: darken(@brand-light, 2%);
  }
}

.list-issues {
  li:hover {
    background-color: darken(@brand-light, 2%);
  }
}
