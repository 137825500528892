// reset
html {
  background-color: @body-bg;
  overflow-x: hidden;
}
body {
  font-family: @font-family-base;
  font-size: @font-size-base;
  color: @text-color;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: @line-height-base;
}

*:focus {
  outline: 0 !important;
}

.h1, .h2, .h3, .h4, .h5, .h6{
  margin: 0;
}

// Links
// -------------------------

a {
  color: @link-color;
  text-decoration: none;
}
a:hover,
a:focus {
  color: @link-hover-color;
  text-decoration: none;
}

label{font-weight: normal;}
small, .small{font-size: @font-size-sm;}

.badge, .label{font-weight: bold;}
.badge{
  background-color: @badge-bg;
  &.up{
    position: relative;
    top: -10px;
    padding:3px 6px;
    margin-left: -10px;
  }
}

.badge-sm{
  font-size: 85%;
  padding: 2px 5px !important;
}

.label-sm{
  padding-top: 0;
  padding-bottom: 0;
}

.badge-white {
  background-color: transparent;
  border: 1px solid rgba(255,255,255,0.35);
  padding: 2px 6px;
}

.badge-empty {
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.15);
  color: inherit;
}

.caret-white{
  border-top-color: #fff;
  border-top-color: rgba(255,255,255,.65);
  a:hover & {
    border-top-color: #fff;
  }
}

.tooltip-inner{  
  background-color: @tooltip-fallback-color;
  background-color: @tooltip-color;
}

.tooltip {
  &.top .tooltip-arrow {
    border-top-color: @tooltip-fallback-color;
    border-top-color: @tooltip-color;
  }
  &.right .tooltip-arrow {
    border-right-color: @tooltip-fallback-color;
    border-right-color: @tooltip-color;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: @tooltip-fallback-color;
    border-bottom-color: @tooltip-color;
  }
  &.left .tooltip-arrow {
    border-left-color: @tooltip-fallback-color;
    border-left-color: @tooltip-color;
  }
}

.thumbnail{
  border-color: @border-color;
}

.popover-content{
  font-size: 12px;
  line-height: 1.5
}
.progress-xs{
  height: 6px
}
.progress-sm{
  height: 10px;
  .progress-bar{
    font-size: 10px;line-height: 1em
  }
}

.progress, .progress-bar{
  .box-shadow(none);
}

.breadcrumb{
  background-color: #fff;
  border:1px solid @border-color;
  padding-left: 10px;
  margin-bottom: 10px;
  > li + li:before,
  > .active{
    color: inherit;
  }
}

.accordion-group,
.accordion-inner{
  border-color: @border-color;
  border-radius: @border-radius-base;
}

.alert{
  font-size: @font-size-sm;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
  .close i{
    font-size: 12px;
    font-weight: normal;
    display: block;
  }
}

.form-control{
  border-color: @input-border;
  border-radius: @input-border-radius;
  &,
  &:focus {    
    .box-shadow(none);
  }
  &:focus{
    border-color: @input-border-focus;
  }
}

.input-s-sm{
  width: 120px
}

.input-s{
  width: 200px
}

.input-s-lg{
  width: 250px
}

.input-lg{
  height: 45px;
}

.input-group-addon{
  border-color: @input-border;
  background-color: lighten(@brand-light, 3%);
}

.list-group{
  border-radius: @border-radius-base;  
  &.no-radius {
    .list-group-item{
      border-radius: 0 !important;
    }
  }
  &.no-borders {
    .list-group-item{
      border: none;
    }
  }
  &.no-border{
    .list-group-item{
      border-width: 1px 0;
    }
  }
  &.no-bg{
    .list-group-item{
      background-color: transparent;
    }
  }
}

.list-group-item{
  border-color: @border-color;
  padding-right: 15px;
  a&{
    &:hover,
    &:focus{
      background-color: lighten(@brand-light, 2%)
    }
  }
  &.media {
    margin-top: 0;
  }
  &.active {
    color: #fff;
    border-color: @list-group-active-color !important;
    background-color: @list-group-active-color !important;
    .text-muted{
      color: lighten(@list-group-active-color, 30%);
    }
    a{
      color: #fff;
    }    
  }
  
  .list-group-alt & {
    &:nth-child(2n+2){
      background-color: rgba(0,0,0,0.02) !important;
    }
  }
  .list-group-lg & {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .list-group-sp & {
    margin-bottom: 5px;
    border-radius: 3px;
  }
  > .badge{
    margin-right: 0;
  }
  > .fa-chevron-right {
    float: right;
    margin-top: 4px;
    margin-right: -5px;
    & + .badge{
      margin-right: 5px;
    }
  }
}

.nav-pills{
  &.no-radius {
    > li{
      > a {
        border-radius: 0;
      }
    }
  }
  > li{
    &.active {
      > a{
        color: #fff !important;
        background-color: @brand-info;
      }
    }
  }
}

.nav{
  > li{
    > a{
      &:hover,
      &:focus{
        background-color: lighten(@brand-light, 1.5%);
      }
    }
  }
  &.nav-sm{
    > li > a{
      padding: 6px 8px;
    }
  }
  .avatar{
    width: 30px;
    margin-top: -5px;
    margin-right: 5px;
  }
  .open{
    > a{
      &,
      &:hover,
      &:focus{
        background-color: lighten(@brand-light, 1.5%);
      }
    }
  }
}

.nav-tabs{
  border-color: @border-color;
  > li{
    > a{     
      border-radius: @panel-border-radius @panel-border-radius 0 0;      
      border-bottom-color: @border-color !important;
    }
    &.active > a{
      border-color: @border-color !important;
      border-bottom-color: #fff !important;
    }
  }
}

.pagination{
  > li{
    > a{
      border-color: @border-color;
      &:hover,
      &:focus{
        border-color: @border-color;
        background-color: @brand-light;
      }
    }
  }
}

.panel{
  &.panel-default{
    border-color: @panel-border;
    > .panel-heading,
    > .panel-footer {
      border-color: @panel-border;
    }
  }
  > .list-group{
    .list-group-item:first-child{
      border-top: 0;
    }
  }
  .list-group-item{
    border-color: lighten(@border-color, 3%);
  }
  border-radius: @panel-border-radius;
  &.no-borders{
    border-width: 0;
    .panel-heading,
    .panel-footer{
      border-width: 0;
    }
  }
  .table{
    td,
    th {
      padding:8px 15px;
      border-top: 1px solid @border-color;
    }
    thead > tr > th{
      border-bottom: 1px solid @border-color;
    }
  }
  .table-striped{
    > tbody {
      > tr{
        &:nth-child(odd){
          > td,
          > th{
            background-color: lighten(@brand-light, 2%);
          }
        }
      }
    }
    > thead {
      th{
        background-color: lighten(@brand-light, 2%);
        border-right: 1px solid @border-color;
        &:last-child{
          border-right: none
        }
      }
    }
  }
}

.panel-heading{
  .panel-default &{
    background-color: lighten(@brand-light, 2%)
  }
  border-radius: @panel-border-radius @panel-border-radius 0 0;
  &.no-border{
    margin:-1px -1px 0 -1px;
    border: none;
  }
  .nav{
    margin: -10px  -15px;    
  }
  .nav-tabs{
    margin: -11px  -16px;
    &.nav-justified{
      width: auto;
    }
    > li{
      > a{
        margin: 0;
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }
  }
  .list-group{
    background: transparent;
  }
}

.panel-footer{
  border-color: @panel-border;
  border-radius: 0 0 @panel-border-radius @panel-border-radius;
  background-color: lighten(@brand-light, 2%);
}

.panel-group .panel-heading + .panel-collapse .panel-body{
  border-top: 1px solid #eaedef;
}

.open{
  z-index: 1050;
  position: relative;
}

.dropdown-menu{
  font-size: 13px;
  border-radius: @border-radius-base;
  .box-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
  border: 1px solid #ddd;
  border: 1px solid rgba(0, 0, 0, 0.1);
  &.pull-left{
    left:100%;
  }
  > .panel{
    border: none;
    margin: -5px 0;
  }
  > li > a{
    padding: 5px 15px;
  }
  > li > a:hover,
  > li > a:focus,
  > .active > a,
  > .active > a:hover, 
  > .active > a:focus{
    background-image: none;
    filter:none;
    background-color: @brand-light !important;
    color: @link-hover-color;
  }
}

.dropdown-header{
  padding: 5px 15px;
}

.dropdown-submenu{
  position: relative;
  &:hover,
  &:focus{
    > a{
      background-color: @brand-light !important;
      color: @text-color;
    }    
    > .dropdown-menu {
      display: block;
    }
  }
  &.pull-left{
    float: none !important;
    > .dropdown-menu{
      left: -100%;
      margin-left: 10px;
    }
  }
  .dropdown-menu{
    left:100%;
    top:0;
    margin-top: -6px;
    margin-left: -1px
  }

  .dropup & {
    > .dropdown-menu{
      top: auto;
      bottom: 0;
    }
  }
}

.dropdown-select > li > a input{position: absolute;left: -9999em;}

/*Carousel*/
.carousel-control{
  width: 40px;
  color: #999;
  text-shadow:none;
  &:hover,
  &:focus{
    color: #ccc;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90);
  }
  &.left, 
  &.right{
    background-image:none;
    filter:none;
  }
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
  }
}
.carousel-indicators{  
  &.out{
    bottom: -5px;
  }
  li{
    .transition(background-color .25s);
    background: #ddd;
    background-color: rgba(0,0,0,0.2);
    border: none;
  }
  .active{
    background: #f0f0f0;
    background-color: rgba(200,200,200,0.2);
    width: 10px;
    height: 10px;
    margin:1px;
  }
}

.carousel.carousel-fade{
  .item {
    .transition(opacity .25s);
    .backface-visibility(hidden);
    opacity: 0;filter: alpha(opacity=0);
  }
  .active{
    opacity: 1;
    filter: alpha(opacity=1);
  }
  .active.left,
  .active.right {
    left: 0;
    z-index: 2;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .next,
  .prev {
    left: 0;
    z-index: 1;
  }
  .carousel-control {
    z-index: 3;
  }
}

/*cols*/
.col-lg-2-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-0{clear:left;}

.row.no-gutter{
  margin-left: 0;
  margin-right: 0;
}

.no-gutter [class*="col"]{
  padding: 0;
}

.modal-backdrop{
  background-color: @brand-dark;
  &.in{
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
}

.modal-over{
  width: 100%;
  height: 100%;
  position:relative;
  background: @brand-dark;
}

.modal-center{
  position: absolute;
  left:50%;
  top:50%;
}

.modal-content{
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}

.icon-muted{
  color: #ccc;
}

.navbar-inverse .navbar-collapse, 
.navbar-inverse .navbar-form{
  border-color:transparent;
}

.navbar-fixed-top,
.navbar-fixed-bottom{
  position: fixed !important;
}

.navbar-fixed-top{
  + *{
      padding-top: 50px;
    }
  &.header-md{
    + *{
      padding-top: @header-md-height;
    }
  }
}