.chat-item{
	.clearfix();	
	.arrow{
		top: 20px;
		&.right{
			&:after{
				border-left-color: @brand-light;
			}
		}
	}
	.chat-body {
		position: relative;
		margin-left: 45px;
		min-height: 30px;
		.panel{
			margin: 0 -1px;
		}
	}
	&.right{
		.chat-body {
			margin-left: 0;
			margin-right: 45px;
		}
	}
	&+.chat-item{
		margin-top: 15px;
	}
}

.comment-list {
	position: relative;
	.comment-item {
		margin-top: 0;
		position: relative;
		> .thumb-sm {
			width: 36px;
		}
		.arrow.left{
			top: 20px;
			left: 39px;
		}
		.comment-body {
			margin-left: 46px;
		}
		.panel-body{
			padding: 10px 15px;
		}
		.panel-heading,
		.panel-footer {
			position: relative;
			font-size: 12px;
			background-color: #fff;
		}
	}

	.comment-reply {
		margin-left: 46px
	}

	&:before{
		position: absolute;
		top: 0;
		bottom: 35px;
		left: 18px;
		width: 1px;
		background: #e0e4e8;
		content: '';
	}
}

/*Timeline*/
.timeline{
	display: table;
	width: 100%;
	border-spacing: 0;
	table-layout: fixed;
	position: relative;
	border-collapse: collapse;
	&:before {
	  content: "";
	  width: 1px;
	  margin-left: -1px;
	  position: absolute;
	  left: 50%;
	  top: 0;
	  bottom: 30px;
	  background-color: #ddd;
	  z-index: 0;
	}
	.timeline-date {
		position: absolute;
		width: 150px;
		left: -200px;
		top: 50%;
		margin-top:-9px;
		text-align: right;
	}
	.timeline-icon {
		position: absolute;
		left: -41px;
		top: -2px;
		top: 50%;
		margin-top: -15px;
	}
	.time-icon {
		width: 30px;
		height: 30px;
		line-height: 30px;
		display:inline-block !important;
		z-index: 10;
		border-radius: 20px;
		text-align: center;
		&:before {
			font-size: 14px;
			margin-top: 5px;
		}
	}
}

.timeline-item{
	display: table-row;
	&:before,
	&.alt:after {
		content: "";
		display: block;
		width: 50%;
	}
	&.alt {
		text-align: right;
		&:before {
			display: none;
		}
		.panel {
			margin-right: 25px;
			margin-left: 0
		}
		.timeline-date {
			left: auto;
			right: -200px;
			text-align: left;
		}
		.timeline-icon {
			left: auto;
			right: -41px;
		}
	}
	&.active{
		display: table-caption;
		text-align: center;
		&:before {
			width: 1%;
		}
		.timeline-caption {
			display: inline-block;
			width: auto;
			h5 span{
				color: #fff;
			}
		}
		.panel {
			margin-left: 0;
		}
		.timeline-date,
		.timeline-icon {
			position: static;
			margin-bottom:10px;
			display: inline-block;
			width: auto;
		}
	}

}

.timeline-caption{
  display: table-cell;
  vertical-align: top;
  width: 50%;
  .panel{
  	position: relative;
  	margin-left: 25px;
  	position: relative;
  	text-align: left;
  }
  h5{
  	margin:0;
  	span {
  		display: block;
  		color: #999;
  		margin-bottom: 4px;
  		font-size: 12px
  	}
  }
  p{
  	font-size: 12px;
  	margin-bottom: 0;
  	margin-top: 10px;
  }
}

.timeline-footer{
	display: table-row;
	a{
		display: table-cell;
		text-align: right;
	}
	.time-icon{
		margin-right: -15px;
		z-index: 5;
	}
}

.post-item{
	border-radius:3px;
	background-color:#fff;
	.box-shadow(0px 1px 2px rgba(0,0,0,0.15));
	margin-bottom:15px;
	.post-title{
		margin-top:0;
	}
	.post-media{
		text-align:center;
		img{
			border-radius: 3px 3px 0 0;
		}
	}
}

.paper {
    position: relative;
    background: -webkit-linear-gradient(top, #f0f0f0 0%, white 5%) 0 0;
    background: -moz-linear-gradient(top, #f0f0f0 0%, white 5%) 0 0;
    background: linear-gradient(top, #f0f0f0 0%, white 5%) 0 0;
    -webkit-background-size: 100% 30px;
    -moz-background-size: 100% 30px;
    -ms-background-size: 100% 30px;
    background-size: 100% 30px;
    &:before{
    	content:''; position:absolute; width:0px; top:0; left:39px; bottom:0; border-left:1px solid @brand-info;
    }
    textarea{
    	border: none;
    	background-color: transparent;
    	height: 100%;
    	padding:30px 0 0 55px;
    	line-height: 30px;
    	min-height: 210px;
    }
}